import React, {useMemo, useState} from 'react'
import {Formik} from 'formik'
import {isEqual} from 'lodash'
import {useFactoryWalletUIContext} from '../FactoryWalletUIContext'
import {useIntl} from 'react-intl'
import useDebounceFnc from '../../../../../../utils/customHooks/useDebounceFnc'
import DatePicker from 'react-datepicker'
import moment from 'moment'

interface filterInterface {
	end?: string;
	start?: string;
	factoryId?: number;
}
interface filterValues {
	end: string;
	start: string;
	factoryId: string
}
const prepareFilter = (queryParams: any, values: filterValues) => {
  const {end, start, factoryId} = values

  const newQueryParams = {...queryParams}
  const filter: filterInterface = {
		factoryId: undefined,
		end: undefined,
		start: undefined,
  }

  //Filter by reference
  if (factoryId) {
    filter.factoryId = Number(factoryId)
  }

	if (end) {
		filter.end = end;
	}

	if (start) {
		filter.start = start;
	}

  newQueryParams.filter = filter
  return newQueryParams
}

// export interface PurchasesFilterProp {
//   listLoading: boolean
// }
const FactoryWalletFilter: React.FC<{}> = () => {
  // Purchases UI Context
  const intl = useIntl()
  const UIContext = useFactoryWalletUIContext();
  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext?.queryParams,
      setQueryParams: UIContext?.setQueryParams,
			factories: UIContext?.factories ?? [],
    }
  }, [UIContext])
  const [start, setStart] = useState<Date | null>(moment().startOf('year').toDate())
  const [end, setEnd] = useState<Date | null>(moment().toDate())

  // queryParams, setQueryParams,
  const applyFilter = useDebounceFnc((values: filterValues) => {
    const newQueryParams = prepareFilter(UIProps.queryParams, values)
    if (!isEqual(newQueryParams, UIProps.queryParams)) {
      newQueryParams.pageNumber = 1
      // update list by queryParams
      UIProps.setQueryParams?.(newQueryParams)
    }
  }, 400)

  return (
    <>
      <Formik
        initialValues={{
					factoryId: '',
					start: moment().startOf('year').format("YYYY-MM-DD"),
					end: moment().format("YYYY-MM-DD"),
        }}
        onSubmit={(values) => {
          applyFilter(values)
        }}
      >
        {({values, handleSubmit, handleBlur, handleChange, setFieldValue}) => (
          <form onSubmit={handleSubmit} className='form form-label-right'>
            <div className='form-group row'>
              {/* FILTRAR POR FABRICA */}
              <div className="col-lg-3 mb-2">
                <select
                  className="form-control"
                  name="factoryId"
                  placeholder={intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_FACTORY"})}
                  onChange={(e) => {
                    setFieldValue("factoryId", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.factoryId}
                >
                  <option value={""}>{intl.formatMessage({id:"LABELS.FILTER.CHOOSE_FACTORY"})}</option>
                  {UIProps.factories.map((f) => (
                    <option value={f.id} key={f.id}>{f.name}</option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  {intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_FACTORY"})}
                </small>
              </div>

              {/* FILTRAR POR START_DATE */}
              <div className='col-lg-3 mb-2'>
                <DatePicker
                  className='form-control'
                  selected={start}
                  isClearable
                  onChange={(date) => {
                    setStart(date)
                    if (date === null) {
                      setFieldValue('start', '')
                      handleSubmit()
                      return
                    }
                    setFieldValue(
                      'start',
                      moment(date).format('YYYY-MM-DD')
                    )
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>Filtrar por fecha de inicio</small>
              </div>

              {/* FILTRAR POR END_DATE */}
              <div className='col-lg-3 mb-2'>
                <DatePicker
                  className='form-control'
                  selected={end}
                  isClearable
                  onChange={(date) => {
                    setEnd(date)
                    if (date === null) {
                      setFieldValue('end', '');
                      handleSubmit()
                      return
                    }
                    setFieldValue(
                      'end',
                      moment(date).format('YYYY-MM-DD')
                    )
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>Filtrar por fecha final</small>
              </div>

            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default FactoryWalletFilter;
