import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { useIntl } from 'react-intl';

//Paginas
import { WalletPage } from './pages/wallet/WalletPage';
import { InventoryPage } from './pages/inventory/InventoryPage';
import { ProfitsPage } from './pages/profits/ProfitsPage';
import { ProfitsInvestorsPage } from './pages/profits-investors/ProfitsInvestorsPage';
import FactoryWalletPage from './pages/factory-wallet/FactoryWalletPage';

const ReportsPage: React.FC = () => {
    const intl = useIntl()
    const reportsBreadCrumbs: Array<PageLink> = [
        {
            title: 'Reports',
            path: '/reports/wallet',
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'Inventory',
            path: '/reports/inventory',
            isSeparator: true,
            isActive: false,
        },
        {
            title: 'Profits',
            path: '/reports/profits',
            isSeparator: true,
            isActive: false,
        },
        {
            title: 'Profits Investors',
            path: '/reports/profits-investors',
            isSeparator: true,
            isActive: false,
        },
        {
            title: 'Factory Wallet',
            path: '/reports/factory-wallet',
            isSeparator: true,
            isActive: false,
        },
    ]
    return(
        <>
            <Switch>
                <Route path='/reports/wallet'>
                    <PageTitle breadcrumbs={reportsBreadCrumbs}> Cartera </PageTitle>
                    <WalletPage/>
                </Route>

                <Route path='/reports/inventory'>
                    <PageTitle breadcrumbs={reportsBreadCrumbs}> Inventario </PageTitle>
                    <InventoryPage/>
                </Route>
                
                <Route path='/reports/profits'>
                    <PageTitle breadcrumbs={reportsBreadCrumbs}> Ganancias </PageTitle>
                    <ProfitsPage />
                </Route>
                
                <Route path='/reports/profits-investors'>
                    <PageTitle breadcrumbs={reportsBreadCrumbs}> Ganancias Inversionistas </PageTitle>
                    <ProfitsInvestorsPage />
                </Route>

                <Route path='/reports/factory-wallet'>
                    <PageTitle breadcrumbs={reportsBreadCrumbs}> Cartera de fábricas </PageTitle>
                    <FactoryWalletPage path='/reports/factory-wallet' />
                </Route>

                <Redirect from='/reports' exact={true} to='/reports/inventory'/>
                <Redirect to='/reports/inventory'/>
            </Switch>
        </>
    )

}


export default ReportsPage;
