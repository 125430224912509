import React, {useMemo} from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../_metronic/partials/control'
import {PreloadingPage} from '../../../../jsxcomponents/PreloadingPage'
import {useIntl} from 'react-intl'
import {useSupplyProjectionUIContext} from './SupplyProjectionUIContext'
import SupplyProjectionTable from './table'
import SupplyProjectionFilter from './filter'

const SupplyProjectionCard: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
  const intl = useIntl()

  const UIContext = useSupplyProjectionUIContext()
  const UIProps = useMemo(
    () => ({
      openEditDialog: UIContext?.openEditDialog,
      isLoading: UIContext?.isLoading ?? true,
    }),
    [UIContext]
  )

  return (
    <Card>
      <PreloadingPage isVisible={UIProps.isLoading} />
      <CardHeader title={intl.formatMessage({id: 'SUPPLY_PROJECTION'})}>
        <CardHeaderToolbar>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => UIProps.openEditDialog?.(null)}
          >
            {intl.formatMessage({id: 'SUPPLY_PROJECTION.NEW'})}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
				<SupplyProjectionFilter />
				<SupplyProjectionTable />
        {children}
      </CardBody>
    </Card>
  )
}

export default SupplyProjectionCard
