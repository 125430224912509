import React, {FC} from 'react'
import { LoadingDialog } from "../../../../../../_metronic/partials/control";

interface LoadingDialogProps {
  color?: string
}

const Loading: FC<LoadingDialogProps> = ({color}) => {
  return <LoadingDialog color={color} text="Cargando ..." />;
}

export default Loading
