import React, {useMemo} from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../_metronic/partials/control'
import {PreloadingPage} from '../../../../jsxcomponents/PreloadingPage'
import {useIntl} from 'react-intl'
import {useFactoryWalletUIContext} from './FactoryWalletUIContext'
import FactoryWalletTable from './table'
import FactoryWalletFilter from './filter'
import {Download} from '@mui/icons-material'
import { initialFilter } from './FactoryWalletUIHelpers'
import useBalanceByFactory from './hooks/useBalanceByFactory'
import useGenerateExel from './hooks/useGenerateExcel'

const FactoryWalletCard: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
  const intl = useIntl()

  const UIContext = useFactoryWalletUIContext()
  const UIProps = useMemo(
    () => ({
      isLoading: UIContext?.isLoading ?? true,
			queryParams: UIContext?.queryParams ?? initialFilter
    }),
    [UIContext]
  )
	const getFactoryBalance = useBalanceByFactory(UIProps.queryParams.filter);
	const download = useGenerateExel(getFactoryBalance);
	
  return (
    <Card>
      <PreloadingPage isVisible={UIProps.isLoading} />
      <CardHeader title={intl.formatMessage({id: 'TEMPLATE'})}>
        <CardHeaderToolbar>
          <button
            type='button'
            className='btn btn-success btn-sm mx-1'
            onClick={() => download()}
						disabled={!UIProps.queryParams.filter.factoryId}
          >
            Descargar Excel <Download />
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
				<FactoryWalletFilter />
				<FactoryWalletTable />
        {children}
      </CardBody>
    </Card>
  )
}

export default FactoryWalletCard
