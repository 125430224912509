//Librerias
import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'

//Componentes
import { WalletUIProvider } from './WalletUIContext'
import { WalletCard } from './WalletCard'
import { WalletEditDialog } from './wallet-edit-dialog/WalletEditDialog'

export const WalletPage = () => {
	const history = useHistory()
	const walletUIEvents = {
		openEditWalletDialog: (id: string) => {
			history.push(`/reports/wallet/${id}/edit`)
		},
	}

	return (
		<WalletUIProvider walletUIEvents={walletUIEvents}>
			<Switch>
				<Route path='/reports/wallet/:id/edit'>
					{({ history, match }) => (
						<WalletEditDialog
							show={match !== null}
							id={match && match.params.id}
							onHide={() => {
								history.push('/reports/wallet')
							}}
						/>
					)}
				</Route>
			</Switch>
			<WalletCard />
		</WalletUIProvider>
	)
}
