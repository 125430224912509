import { Field, Form, Formik } from 'formik'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { SupplyProjectionModel } from '../models'
import * as Yup from 'yup'
import LoadingDialog from '../loading-dialog'
import { useSupplyProjectionUIContext } from '../SupplyProjectionUIContext'
import { Select } from '../../../../../../_metronic/partials/control'
import ReferencesTable from './ReferencesTable'
import SupplyAggTable from './SupplyAggTable'

const validationSchema = Yup.object().shape({
	factoryId: Yup.number().required('Requerido'),
	supplyProjectionReferences: Yup.array()
		.min(1, 'Ingrese al menos un insumo')
		.of(
			Yup.object().shape({
				name: Yup.string().required('Requerido'),
				factoryId: Yup.number().min(1, 'Requerido').required('Requerido'),
				quantity: Yup.number().min(1, 'Requerido').required('Requerido'),
			})
		),
})

interface EditFormProps {
	save: (values: SupplyProjectionModel) => Promise<void>
	loadingData: boolean
	data: SupplyProjectionModel | null
	onHide: () => void
}

const EditForm: FC<EditFormProps> = ({ save, loadingData, data, onHide }) => {
	const intl = useIntl()
	const UIContext = useSupplyProjectionUIContext()
	const UIProps = useMemo(
		() => ({
			factories: UIContext?.factories ?? [],
		}),
		[UIContext]
	)
	const [loading, setLoading] = useState(false)

	if (loadingData || loading) return <LoadingDialog color='primary' />

	return (
		<Formik
			enableReinitialize
			initialValues={{
				factoryId: data?.factoryId ?? '',
				supplyProjectionReferences: data?.supplyProjectionReferences ?? [],
			}}
			validationSchema={validationSchema}
			onSubmit={async (values) => {
				try {
					setLoading(true)
					await save({
						factoryId: Number(values.factoryId),
						supplyProjectionReferences: values.supplyProjectionReferences.map((spr) => ({
							name: spr.name,
							factoryId: spr.factoryId,
							quantity: Number(spr.quantity),
						})),
					})
				} catch (error) {
				} finally {
					setLoading(false)
				}
			}}
		>
			{({ values, setFieldValue }) => (
				<Form>
					<Modal.Body>
						<Row>
							<Col md='6' className='mb-4'>
								<Field
									name='factoryId'
									label='Fabrica'
									disabled={data !== null || values.supplyProjectionReferences.length !== 0}
									component={Select}
									onChange={(e: any) => {
										setFieldValue('factoryId', e.target.value)
									}}
									value={values.factoryId}
								>
									<option value={0}>{'Escoja una fabrica'}</option>
									{UIProps.factories.map((f, index) => (
										<option value={f.id} key={index}>
											{f.name}
										</option>
									))}
								</Field>
							</Col>
						</Row>
						<ReferencesTable factoryId={values.factoryId} />
						<br />
						<SupplyAggTable
							factoryId={values.factoryId}
							supplyProjectionReferences={values.supplyProjectionReferences}
						/>
					</Modal.Body>
					<Modal.Footer>
						<button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
							{intl.formatMessage({ id: 'BUTTON.CANCEL' })}
						</button>
						<button type='submit' className='btn btn-primary btn-elevate'>
							{intl.formatMessage({ id: 'BUTTON.SAVE' })}
						</button>
					</Modal.Footer>
				</Form>
			)}
		</Formik>
	)
}

export default EditForm
