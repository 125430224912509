import React, {
	createContext,
	useContext,
	useState,
	useCallback,
	useEffect,
	FC,
	PropsWithChildren,
} from 'react'
import { isEqual, isFunction } from 'lodash'
import { initialFilter, initialValues } from './SupplyProjectionUIHelpers'

//Modelos
import { SupplyProjectionModel } from './models'
import { FactoryModel } from '../../../admin/pages/factories/models'
import axios, { CancelToken } from 'axios'
import { ReferencesModel } from '../references/models'
import { Inventory } from '../orders/order-edit-dialog/OrderEditForm'
import { SupplyModel } from '../../../admin/pages/supplies/models'

interface dataDataResultTableSupplyProjection {
	entities: any[]
	totalCount: number
	errorMessage: string
}
export interface resultTableSupplyProjection {
	data: dataDataResultTableSupplyProjection
	totalCount: number
}

type SetState<T = any> = React.Dispatch<React.SetStateAction<T>>

export interface SupplyProjectionUIContextModel {
	queryParams: typeof initialFilter
	setQueryParamsBase: SetState<typeof initialFilter>
	setQueryParams: SetState<typeof initialFilter>
	valuesTable: resultTableSupplyProjection
	setValuesTable: SetState<resultTableSupplyProjection>
	selected: SupplyProjectionModel | null
	selectedName: string
	isLoadingSelected: boolean
	tableLoading: boolean
	setTableLoading: SetState<boolean>
	isLoading: boolean
	setIsLoading: SetState<boolean>
	factories: FactoryModel[]
	references: ReferencesModel[]
	inventory: Inventory[]
	supplies: SupplyModel[]
	loadTable: () => Promise<void>
	select: (id: number | null) => Promise<void>
	loadReferences: (factoryId?: string | number, token?: CancelToken) => Promise<void>
	loadInventory: (factoryId?: string | number, token?: CancelToken) => Promise<void>
	loadSupplies: (factoryId?: string | number, token?: CancelToken) => Promise<void>
	openEditDialog: (id: number | null) => void
	openDeleteDialog: (id: number | null) => void
}
const UIContext = createContext<SupplyProjectionUIContextModel | null>(null)

export function useSupplyProjectionUIContext() {
	return useContext(UIContext)
}

export const UIConsumer = UIContext.Consumer

export type SupplyProjectionUIProviderProps = {
	UIEvents: any
}

const SupplyProjectionUIProvider: FC<PropsWithChildren<SupplyProjectionUIProviderProps>> = ({
	children,
	UIEvents,
}) => {
	const [queryParams, setQueryParamsBase] = useState(initialFilter)
	const [valuesTable, setValuesTable] = useState<resultTableSupplyProjection>(initialValues)
	const [selected, setSelected] = useState<SupplyProjectionModel | null>(null)
	const [selectedName, setSelectedName] = useState('')
	const [factories, setFactories] = useState<FactoryModel[]>([])
	const [references, setReferences] = useState<ReferencesModel[]>([])
	const [inventory, setInventory] = useState<Inventory[]>([])
	const [supplies, setSupplies] = useState<SupplyModel[]>([])

	const [tableLoading, setTableLoading] = useState(true)
	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingSelected, setIsLoadingSelected] = useState(false)

	const setQueryParams: SetState<typeof initialFilter> = useCallback((next) => {
		setQueryParamsBase((prev) => {
			if (isFunction(next)) {
				next = next(prev)
			}

			if (isEqual(prev, next)) {
				return prev
			}

			return next
		})
	}, [])

	const loadTable = useCallback(async () => {
		try {
			setTableLoading(true)
			const result = await axios.get(`${process.env.REACT_APP_API_URL}/supplyProjection/table`, {
				params: {
					pageNumber: queryParams.pageNumber,
					pageSize: queryParams.pageSize,
					factoryId: queryParams.filter.factoryId,
					start: queryParams.filter.start,
					end: queryParams.filter.end,
				},
			})

			setValuesTable(result.data)
		} catch (error) {
			console.log(error)
			setValuesTable(initialValues)
		} finally {
			setTableLoading(false)
		}
	}, [
		queryParams.pageSize,
		queryParams.pageNumber,
		queryParams.sortField,
		queryParams.sortOrder,
		queryParams.filter,
	])

	const select = useCallback(async (id: number | null) => {
		try {
			setIsLoadingSelected(true)
			if (id === null) {
				setSelected(null)
				setSelectedName('')
				return
			}
			const result = await axios.get(`${process.env.REACT_APP_API_URL}/supplyProjection/${id}`)
			setSelected(result.data)
			setSelectedName(`Proyección #${result.data?.id || ''}`)
		} catch (error) {
			console.log(error)
			setSelected(null)
			setSelectedName('')
		} finally {
			setIsLoadingSelected(false)
		}
	}, [])

	const loadReferences = useCallback(async (factoryId?: string | number, token?: CancelToken) => {
		try {
			if (!factoryId) {
				setReferences([])
				return
			}
			const res = await axios.get(`${process.env.REACT_APP_API_URL}/references`, {
				cancelToken: token,
				params: {
					factoryId,
				},
			})
			setReferences(res.data)
		} catch { }
	}, [])

	const loadInventory = useCallback(async (factoryId?: string | number, token?: CancelToken) => {
		try {
			if (!factoryId) {
				setInventory([])
				return
			}
			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/factory/getSupplyInventory/${factoryId}`,
				{
					cancelToken: token,
				}
			)
			setInventory(res.data)
		} catch { }
	}, [])

	const loadSupplies = useCallback(async (factoryId?: string | number, token?: CancelToken) => {
		try {
			if (!factoryId) {
				setSupplies([]);
				return
			}
			const res = await axios.get(`${process.env.REACT_APP_API_URL}/supply`, {
				cancelToken: token,
			})
			setSupplies(res.data)
		} catch { }
	}, [])

	useEffect(() => {
		loadTable()
	}, [
		queryParams.pageSize,
		queryParams.pageNumber,
		queryParams.sortField,
		queryParams.sortOrder,
		queryParams.filter,
	])

	useEffect(() => {
		; (async () => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_API_URL}/factory`)
				setFactories(response.data)
			} catch (error) {
				setFactories([])
			}
		})()
	}, [])

	return (
		<UIContext.Provider
			value={{
				queryParams,
				setQueryParams,
				setQueryParamsBase,
				isLoading,
				setIsLoading,
				tableLoading,
				setTableLoading,
				valuesTable,
				setValuesTable,
				loadTable,
				selected,
				isLoadingSelected,
				select,
				selectedName,
				factories,
				references,
				inventory,
				supplies,
				loadReferences,
				loadInventory,
				loadSupplies,
				openEditDialog: UIEvents.openEditDialog,
				openDeleteDialog: UIEvents.openDeleteDialog,
			}}
		>
			{children}
		</UIContext.Provider>
	)
}

export default SupplyProjectionUIProvider
