import React, { useEffect, useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { ModalProgressBar } from '../../../../../../_metronic/partials/control'
// import * as actions from "../../../_redux/billings/billingsActions";
import { useBillingsUIContext, BillingsUIContextModel } from '../BillingsUIContext'
import axios from 'axios'
import Swal from 'sweetalert2'
import { BillingsLoadingDialog } from '../billings-loading-dialog/BillingsLoadingDialog'

export interface BillingDeleteDialogProps {
	id?: number | null
	fullDelete?: number | null
	show: boolean
	onHide: () => void
}
export const BillingDeleteDialog: React.FC<BillingDeleteDialogProps> = ({
	id,
	fullDelete,
	show,
	onHide,
}) => {
	// Billings UI Context
	const billingsUIContext = useBillingsUIContext() as BillingsUIContextModel
	const billingsUIProps = useMemo(() => {
		return {
			setIds: billingsUIContext.setIds,
			queryParams: billingsUIContext.queryParams,
			selectedBilling: billingsUIContext.selectedBilling,
			fetchBilling: billingsUIContext.fetchBilling,
			loadBilling: billingsUIContext.loadBilling,
			loadBillings: billingsUIContext.loadBillings,
			setIsLoading: billingsUIContext.setIsLoading,
		}
	}, [billingsUIContext])
	const status = 1
	// Billings Redux state
	// const { isLoading } = useSelector(
	//   (state) => ({ isLoading: state.billings.actionsLoading }),
	//   shallowEqual
	// );

	// if !id we should close modal
	useEffect(() => {
		if (!id) {
			onHide()
		} else {
			billingsUIProps.fetchBilling(id as number)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	// looking for loading/dispatch
	// useEffect(() => {}, [ dispatch]);

	const deleteBilling = async () => {
		billingsUIProps.setIsLoading(true)
		try {
			if (fullDelete === 1) {
				await axios.put(`${process.env.REACT_APP_API_URL}/billing/${id}`, {
					...billingsUIProps.selectedBilling,
					// status: billingsUIProps.selectedBilling?.status === 1 ? 0 : 1,
				})
				billingsUIProps.setIsLoading(false)
				Swal.fire({
					title: `Facturación ${status ? 'desactivada' : 'activada'} correctamente`,
					icon: 'success',
				}).then(() => {
					billingsUIProps.loadBillings()
				})
			} else {
				await axios.delete(`${process.env.REACT_APP_API_URL}/billing/${id}`)
				billingsUIProps.setIsLoading(false)
				await Swal.fire({
					title: 'Facturación eliminada correctamente',
					icon: 'success',
				})
				await billingsUIProps.loadBillings()
				onHide()
			}
		} catch (error) {
			console.log('error', error)
			billingsUIProps.setIsLoading(false)
			Swal.fire({
				title: 'Error al desactivar facturación',
				icon: 'error',
			}).then(() => {
				billingsUIProps.loadBillings()
			})
		}
	}

	return (
		<Modal show={show} onHide={onHide} aria-labelledby='example-modal-sizes-title-lg'>
			{/*begin::Loading*/}
			{false && <ModalProgressBar />}
			{/*end::Loading*/}
			<Modal.Header closeButton>
				<Modal.Title id='example-modal-sizes-title-lg'>
					{billingsUIProps.loadBilling
						? 'Cargando...'
						: `
           Eliminar
          facturación ${billingsUIProps.selectedBilling?.reference}`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{billingsUIProps.loadBilling && <BillingsLoadingDialog color={'primary'} />}
				{!billingsUIProps.loadBilling && (
					<span>
						{fullDelete === 1
							? status
								? '¿Está seguro que desea desactivar este facturación?'
								: '¿Está seguro que desea activar este facturación?'
							: '¿Está seguro que desea eliminar este facturación?'}
					</span>
				)}
			</Modal.Body>
			<Modal.Footer>
				{!billingsUIProps.loadBilling && (
					<div>
						<button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
							Cancel
						</button>
						<> </>
						<button
							type='button'
							onClick={deleteBilling}
							className={`btn btn-${fullDelete === 1 ? (status ? 'danger' : 'success') : 'danger'
								} btn-elevate`}
						>
							{fullDelete === 1 ? (status ? 'Desactivar' : 'Activar') : 'Eliminar'}
						</button>
					</div>
				)}
			</Modal.Footer>
		</Modal>
	)
}
