import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  FC,
  PropsWithChildren,
} from 'react'
import {isEqual, isFunction} from 'lodash'
import {initialFilter, initialValues} from './FactoryWalletUIHelpers'

//Modelos
import {FactoryWalletModel} from './models'
import axios from 'axios'
import { FactoryModel } from '../../../admin/pages/factories/models'

interface dataDataResultTableFactoryWallet {
  entities: FactoryWalletModel[]
  totalCount: number
  errorMessage: string
}
export interface resultTableFactoryWallet {
  data: dataDataResultTableFactoryWallet
  totalCount: number
}

type SetState<T = any> = React.Dispatch<React.SetStateAction<T>>

export interface FactoryWalletUIContextModel {
  queryParams: typeof initialFilter
  setQueryParamsBase: SetState<typeof initialFilter>
  setQueryParams: SetState<typeof initialFilter>
  valuesTable: resultTableFactoryWallet
  setValuesTable: SetState<resultTableFactoryWallet>
  selected: FactoryWalletModel | null
  selectedName: string
  isLoadingSelected: boolean
  tableLoading: boolean
  setTableLoading: SetState<boolean>
  isLoading: boolean
  setIsLoading: SetState<boolean>
	factories: FactoryModel[]
  loadTable: () => Promise<void>
  select: (id: number | null) => Promise<void>
	openEditDialog: (id: number | null) => void
	openDeleteDialog: (id: number | null) => void
}
const UIContext = createContext<FactoryWalletUIContextModel | null>(null)

export function useFactoryWalletUIContext() {
  return useContext(UIContext)
}

export const UIConsumer = UIContext.Consumer

export type FactoryWalletUIProviderProps = {
  UIEvents: any
}

const FactoryWalletUIProvider: FC<PropsWithChildren<FactoryWalletUIProviderProps>> = ({children, UIEvents}) => {
  const [queryParams, setQueryParamsBase] = useState(initialFilter)
  const [valuesTable, setValuesTable] = useState<resultTableFactoryWallet>(initialValues)
  const [selected, setSelected] = useState<FactoryWalletModel | null>(null)
  const [selectedName, setSelectedName] = useState('')
	const [factories, setFactories] = useState<FactoryModel[]>([]);

  const [tableLoading, setTableLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSelected, setIsLoadingSelected] = useState(false)

  const setQueryParams: SetState<typeof initialFilter> = useCallback((next) => {
    setQueryParamsBase((prev) => {
      if (isFunction(next)) {
        next = next(prev)
      }

      if (isEqual(prev, next)) {
        return prev
      }

      return next
    })
  }, [])

  const loadTable = useCallback(async () => {
    try {
      setTableLoading(true)
			const result = await axios.get(`${process.env.REACT_APP_API_URL}/factory/getFactoryBalanceAgg`, {
				params: {
					factoryId: queryParams.filter.factoryId,
					start: queryParams.filter.start,
					end: queryParams.filter.end,
				},
			})

			setValuesTable({
				data: {
					entities: result.data,
					totalCount: result.data.length,
					errorMessage: "",
				},
				totalCount: result.data.length,
			})
    } catch (error) {
      console.log(error)
      setValuesTable(initialValues)
    } finally {
      setTableLoading(false)
    }
  }, [
    queryParams.filter,
  ])

  const select = useCallback(async (id: number | null) => {
    try {
      setIsLoadingSelected(true)
      if (id === null) {
        setSelected(null)
        setSelectedName('')
        return
      }
    } catch (error) {
      console.log(error)
      setSelected(null)
      setSelectedName('')
    } finally {
      setIsLoadingSelected(false)
    }
  }, [])

	useEffect(() => {
		loadTable()
	}, [
		queryParams.filter.factoryId,
		queryParams.filter.start,
		queryParams.filter.end,
	]);

	useEffect(() => {
		(async() => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_API_URL}/factory`);
				setFactories(response.data);
			} catch (error) {
				setFactories([])
			}
		})()
	}, [])

  return (
    <UIContext.Provider
      value={{
        queryParams,
        setQueryParams,
        setQueryParamsBase,
        isLoading,
        setIsLoading,
        tableLoading,
        setTableLoading,
        valuesTable,
        setValuesTable,
        loadTable,
        selected,
        isLoadingSelected,
        select,
        selectedName,
				openEditDialog: UIEvents.openEditDialog,
				openDeleteDialog: UIEvents.openDeleteDialog,
				factories,
      }}
    >
      {children}
    </UIContext.Provider>
  )
}

export default FactoryWalletUIProvider
