import React, {useMemo} from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../_metronic/partials/control'
import {PreloadingPage} from '../../../../jsxcomponents/PreloadingPage'
import {useIntl} from 'react-intl'
import {useReferencesUIContext} from './ReferencesUIContext'
import ReferencesTable from './table'
import ReferencesFilter from './filter'

const ReferencesCard: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
  const intl = useIntl()

  const UIContext = useReferencesUIContext()
  const UIProps = useMemo(
    () => ({
      openEditDialog: UIContext?.openEditDialog ?? ((id: number | null) => {}),
      isLoading: UIContext?.isLoading ?? true,
    }),
    [UIContext]
  )

  return (
    <Card>
      <PreloadingPage isVisible={UIProps.isLoading} />
      <CardHeader title={intl.formatMessage({id: 'REFERENCES'})}>
        <CardHeaderToolbar>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => UIProps.openEditDialog(null)}
          >
            {intl.formatMessage({id: 'REFERENCES.NEW'})}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
				<ReferencesFilter />
				<ReferencesTable />
        {children}
      </CardBody>
    </Card>
  )
}

export default ReferencesCard
