import React, { useMemo } from 'react'
import {Route, useHistory, Switch} from 'react-router-dom'
import FactoryWalletCard from './FactoryWalletCard'
import FactoryWalletUIProvider from './FactoryWalletUIContext'

interface FactoryWalletPageProps {
  path: string
}

const FactoryWalletPage: React.FC<FactoryWalletPageProps> = ({path}) => {
	const history = useHistory();

	const UIEvents = useMemo(() => ({
		openEditDialog(id: number | null) {
			if (id === null) {
				history.push(`${path}/new`)
				return;
			}
			history.push(`${path}/${id}/edit`);
		},
    openDeleteDialog(id:number) {
      history.push(`${path}/${id}/delete`);
    },
	}), [history]);
  return (
    <FactoryWalletUIProvider UIEvents={UIEvents}>
      <FactoryWalletCard>
      </FactoryWalletCard>
    </FactoryWalletUIProvider>
  )
}

export default FactoryWalletPage
