import React, { useMemo } from 'react'
import { Route, useHistory, Switch } from 'react-router-dom'
import DeleteDialog from './delete-dialog'
import EditDialog from './edit-dialog'
import ReferencesCard from './ReferencesCard'
import ReferencesUIProvider, { name_factoryId } from './ReferencesUIContext'

interface ReferencesPageProps {
	path: string
}

const ReferencesPage: React.FC<ReferencesPageProps> = ({ path }) => {
	const history = useHistory()

	const UIEvents = useMemo(
		() => ({
			openEditDialog(id: name_factoryId | null) {
				if (id === null) {
					history.push(`${path}/new`)
					return
				}
				history.push(`${path}/${id.name}/${id.factoryId}/edit`)
			},
			openDeleteDialog(id: name_factoryId) {
				history.push(`${path}/${id.name}/${id.factoryId}/delete`)
			},
		}),
		[history]
	)
	return (
		<ReferencesUIProvider UIEvents={UIEvents}>
			<ReferencesCard>
				<Switch>
					<Route
						path={`${path}/new`}
						render={({ history, match }) => (
							<EditDialog show={match !== null} id={null} onHide={() => history.push(path)} />
						)}
					/>
					<Route
						path={`${path}/:name/:factoryId/edit`}
						render={({ history, match }) => (
							<EditDialog
								show={match !== null}
								id={
									match && {
										name: match.params.name,
										factoryId: Number(match.params.factoryId),
									}
								}
								onHide={() => history.push(path)}
							/>
						)}
					/>
					<Route
						path={`${path}/:name/:factoryId/delete`}
						render={({ history, match }) => (
							<DeleteDialog
								show={match !== null}
								id={
									match && {
										name: match.params.name,
										factoryId: Number(match.params.factoryId),
									}
								}
								onHide={() => history.push(path)}
							/>
						)}
					/>
				</Switch>
			</ReferencesCard>
		</ReferencesUIProvider>
	)
}

export default ReferencesPage
