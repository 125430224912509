import React from 'react'
import { Route, useHistory, Switch } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'
// import { OrdersLoadingDialog } from "./orders-loading-dialog/OrdersLoadingDialog";
import { OrderEditDialog } from './order-edit-dialog/OrderEditDialog'
import { OrderDeleteDialog } from './order-delete-dialog/OrderDeleteDialog'
// import { OrdersDeleteDialog } from "./orders-delete-dialog/OrdersDeleteDialog";
// import { OrdersFetchDialog } from "./orders-fetch-dialog/OrdersFetchDialog";
// import { OrdersUpdateStateDialog } from "./orders-update-status-dialog/OrdersUpdateStateDialog";
import { OrdersUIProvider } from './OrdersUIContext'
import { OrdersCard } from './OrdersCard'
import { ProductStocksPage } from '../productStock/ProductStocksPage'
import { OrderPaymentDialog } from './order-payment-dialog/OrderPaymentDialog'

export const OrdersPage = () => {
	const history = useHistory()
	const ordersUIEvents = {
		newOrderButtonClick: () => {
			history.push('/processes/orders/new')
		},
		productStockButtonClick: () => {
			history.push('/processes/product-stock')
		},
		openEditOrderDialog: (id: number) => {
			history.push(`/processes/orders/${id}/edit`)
		},
		openDeleteOrderDialog: (id: number, fullDelete: number) => {
			history.push(`/processes/orders/${id}/${fullDelete}/delete`)
		},
		openOrderPaymentDialog: (id: number) => {
			history.push(`/processes/orders/paymentManufacture/${id}`)
		},
	}

	return (
		<OrdersUIProvider ordersUIEvents={ordersUIEvents}>
			{/* <OrdersLoadingDialog /> */}
			<Switch>
				<Route path='/processes/orders/product-stock'>
					<ProductStocksPage />
				</Route>
				<Route path='/processes/orders/new'>
					{({ history, match }) => (
						<OrderEditDialog
							show={match !== null}
							onHide={() => {
								history.push('/processes/orders')
							}}
						/>
					)}
				</Route>
				<Route path='/processes/orders/:id/edit'>
					{({ history, match }) => {
						return (
							<OrderEditDialog
								show={match !== null}
								id={match && (Number(match.params.id) as number)}
								onHide={() => {
									history.push('/processes/orders')
								}}
							/>
						)
					}}
				</Route>
				<Route path='/processes/orders/:id/:fullDelete/delete'>
					{({ history, match }) => (
						<OrderDeleteDialog
							show={match !== null}
							id={match && Number(match.params.id)}
							fullDelete={match && Number(match.params.fullDelete)}
							onHide={() => {
								history.push('/processes/orders')
							}}
						/>
					)}
				</Route>
				<Route path='/processes/orders/paymentManufacture/:id'>
					{({ history, match }) => (
						<OrderPaymentDialog
							show={match !== null}
							id={match && Number(match.params.id)}
							onHide={() => {
								history.push('/processes/orders')
							}}
						/>
					)}
				</Route>
			</Switch>
			<OrdersCard />
		</OrdersUIProvider>
	)
}
