import React, { useMemo } from 'react'
import { Route, useHistory, Switch } from 'react-router-dom'
import DeleteDialog from './delete-dialog'
import EditDialog from './edit-dialog'
import SupplyProjectionCard from './SupplyProjectionCard'
import SupplyProjectionUIProvider from './SupplyProjectionUIContext'

interface SupplyProjectionPageProps {
	path: string
}

const SupplyProjectionPage: React.FC<SupplyProjectionPageProps> = ({ path }) => {
	const history = useHistory()

	const UIEvents = useMemo(
		() => ({
			openEditDialog(id: number | null) {
				if (id === null) {
					history.push(`${path}/new`)
					return
				}
				history.push(`${path}/${id}/edit`)
			},
			openDeleteDialog(id: number) {
				history.push(`${path}/${id}/delete`)
			},
		}),
		[history]
	)
	return (
		<SupplyProjectionUIProvider UIEvents={UIEvents}>
			<SupplyProjectionCard>
				<Switch>
					<Route
						path={`${path}/new`}
						render={({ history, match }) => (
							<EditDialog show={match !== null} id={null} onHide={() => history.push(path)} />
						)}
					/>
					<Route
						path={`${path}/:id/edit`}
						render={({ history, match }) => (
							<EditDialog
								show={match !== null}
								id={match && Number(match.params.id)}
								onHide={() => history.push(path)}
							/>
						)}
					/>
					<Route
						path={`${path}/:id/delete`}
						render={({ history, match }) => (
							<DeleteDialog
								show={match !== null}
								id={match && Number(match.params.id)}
								onHide={() => history.push(path)}
							/>
						)}
					/>
				</Switch>
			</SupplyProjectionCard>
		</SupplyProjectionUIProvider>
	)
}

export default SupplyProjectionPage
