import {useMemo, FC} from 'react'
import {Modal} from 'react-bootstrap'
import { name_factoryId, useReferencesUIContext } from '../ReferencesUIContext';
import { useIntl } from "react-intl";

interface DialogHeaderProps {
  id?: name_factoryId
}

const DialogHeader: FC<DialogHeaderProps> = ({id}) => {
  const intl = useIntl();

  const UIContext = useReferencesUIContext();
	const UIProps = useMemo(() => ({
		selected: UIContext?.selected ?? null,
		selectedName: UIContext?.selectedName ?? "",
		isLoadingSelected: UIContext?.isLoadingSelected ?? true,
	}), [UIContext]);

  const title = useMemo(() => {
		if (UIProps.isLoadingSelected) return 'Cargando';
    if (id !== undefined && id !== null ) return `Editar ${UIProps.selectedName}`
		return intl.formatMessage({ id: "REFERENCES"})	
  }, [UIProps.isLoadingSelected, UIProps.selectedName, id?.name, id?.factoryId])
  return (
    <Modal.Header closeButton>
      <Modal.Title id='example-modal-sizes-title-lg'>
        <div className='d-flex'>
          {title} {UIProps.isLoadingSelected && <div className='dot-shuttle' />}
        </div>
      </Modal.Title>
    </Modal.Header>
  )
}

export default DialogHeader
