import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import axios, { CancelToken } from 'axios'
import { ReferencesModel, ReferencesSupplies } from '../../references/models'
import { SupplyProjectionReferences } from '../models'
import { useSupplyProjectionUIContext } from '../SupplyProjectionUIContext'
import { Inventory } from '../../orders/order-edit-dialog/OrderEditForm'
import { SupplyModel } from '../../../../admin/pages/supplies/models'
import { Download } from '@mui/icons-material'
import useGenerateExel from '../hooks/useGenerateExcel'

interface SupplyAggTableProps {
	factoryId?: string | number
	supplyProjectionReferences: SupplyProjectionReferences[]
}

const SupplyAggTable: FC<SupplyAggTableProps> = ({ factoryId, supplyProjectionReferences }) => {
	const download = useGenerateExel()

	const UIContext = useSupplyProjectionUIContext()
	const UIProps = useMemo(
		() => ({
			supplies: UIContext?.supplies ?? [],
			inventory: UIContext?.inventory ?? [],
			references: UIContext?.references ?? [],
			loadSupplies: UIContext?.loadSupplies,
			loadInventory: UIContext?.loadInventory,
		}),
		[UIContext]
	)

	const referencesSupplies: ReferencesSupplies[] = useMemo(() => {
		const suppliesMap = new Map<number, ReferencesSupplies>()

		supplyProjectionReferences.forEach((spr) => {
			const reference = UIProps.references.find(
				(r) => r.name === spr.name && r.factoryId === spr.factoryId
			)

			if (reference === undefined) return

			reference.referencesSupplies.forEach((r) => {
				let sup = suppliesMap.get(r.supplyId)

				if (sup === undefined) {
					suppliesMap.set(r.supplyId, {
						supplyId: r.supplyId,
						quantity: Number(r.quantity) * Number(spr.quantity || 0),
					})
					return
				}

				sup.quantity += Number(r.quantity) * Number(spr.quantity || 0)
			})
		})

		// @ts-ignore
		return [...suppliesMap.values()]
	}, [supplyProjectionReferences, UIProps.references])

	const suppliesProjection = useMemo(
		() =>
			referencesSupplies
				.map((item) => {
					const sup = UIProps.supplies.find((s) => s.id === item.supplyId)
					const inv = UIProps.inventory.find((i) => i.supplyId === item.supplyId)
					let pendingQuantity = item.quantity - (inv?.currentQuantity || 0)
					pendingQuantity = pendingQuantity > 0 ? pendingQuantity : 0
					return {
						supplyId: item.supplyId,
						name: sup?.name ?? '',
						reference: sup?.reference ?? '',
						quantity: item.quantity,
						currentQuantity: inv?.currentQuantity ?? 0,
						pendingQuantity,
						unit: sup?.unit?.name ?? '',
					}
				})
				.sort((a, b) => {
					if (a.name < b.name) return -1
					if (a.name > b.name) return 1
					return 0
				}),
		[UIProps.supplies, UIProps.inventory, referencesSupplies]
	)

	useEffect(() => {
		const token = axios.CancelToken.source()

		UIProps.loadInventory?.(factoryId, token.token)
		UIProps.loadSupplies?.(factoryId, token.token)
		return () => {
			token.cancel()
		}
	}, [factoryId])

	return (
		<div className='px-4' style={{ outline: '1px solid #dde', borderRadius: '0.5rem' }}>
			<div className='row pt-4'>
				<div className='col-6'>
					<h4>Insumos Requeridos</h4>
				</div>
				<div className='col-6'>
					<button
						className='btn btn-sm btn-success btn-elevate btn-block float-end'
						onClick={() => {
							download(suppliesProjection)
						}}
						type='button'
					>
						Descargar Excel <Download />
					</button>
				</div>
			</div>
			<div className='form-group row' style={{ overflowX: 'scroll' }}>
				<div>
					<table className='styled-table-success'>
						<thead>
							<tr>
								<th>#</th>
								<th>Insumo</th>
								<th>Referencia</th>
								<th>Cantidad proyectada</th>
								<th>Cantidad en stock</th>
								<th>Cantidad pendiente</th>
								<th>Unidad</th>
							</tr>
						</thead>
						<tbody>
							{suppliesProjection.map((item, index) => (
								<tr key={item.supplyId}>
									<td>{index + 1}</td>
									<td>{item.name}</td>
									<td>{item.reference}</td>
									<td>{item.quantity}</td>
									<td>{item.currentQuantity}</td>
									<td>{item.pendingQuantity}</td>
									<td>{item.unit}</td>
								</tr>
							))}
							{suppliesProjection.length === 0 ? (
								<tr>
									<td colSpan={7} style={{ textAlign: 'center' }}>
										<h4 className='m-2'>Sin Insumos</h4>
									</td>
								</tr>
							) : null}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default SupplyAggTable
