import React, { FC, PropsWithChildren, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/partials/control";
import { PurchasesFilter } from "./purchases-filter/PurchasesFilter";
import { PurchasesTable } from "./purchases-table/PurchasesTable";
// import { PurchasesGrouping } from "./payments-grouping/PurchasesGrouping";
import { usePurchasesUIContext, PurchasesUIContextModel } from './PurchasesUIContext';
import { useIntl } from "react-intl";
import { PreloadingPage } from "../../../../jsxcomponents/PreloadingPage";

export function PurchasesCard({children}: PropsWithChildren<{}>) {
  const intl = useIntl();
  const paymentsUIContext = (usePurchasesUIContext() as PurchasesUIContextModel);
  const paymentsUIProps = useMemo(() => {
    return {
      ids: paymentsUIContext.ids,
      newPurchaseButtonClick: paymentsUIContext.newPurchaseButtonClick,
      isLoading: paymentsUIContext.isLoading
    };
  }, [paymentsUIContext]);

  return (
    <Card>
      <PreloadingPage
        isVisible={paymentsUIProps.isLoading}
      />
      <CardHeader title={intl.formatMessage({id: "PURCHASE.PURCHASES"})}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={paymentsUIProps.newPurchaseButtonClick}
          >
            {intl.formatMessage({id: "PURCHASE.NEW_PURCHASE"})}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <PurchasesFilter />
        {/* {paymentsUIProps.ids.length > 0 && <PurchasesGrouping />} */}
        <PurchasesTable />
				{children}
      </CardBody>
    </Card>
  );
}



