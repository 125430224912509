import { useMemo } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import {
	// getSelectRow,
	getHandlerTableChange,
	NoRecordsFoundMessage,
	PleaseWaitMessage,
	sortCaret,
	headerSortingClasses,
} from '../../../../../../_metronic/helpers'
import { Pagination } from '../../../../../../_metronic/partials/control'
import { useIntl } from 'react-intl'
import * as columnFormatters from './column-formatters'

import * as uiHelpers from '../FactoryWalletUIHelpers'
import { useFactoryWalletUIContext } from '../FactoryWalletUIContext'
import { initialFilter, initialValues } from '../FactoryWalletUIHelpers'
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat'

const ReferencesTable = () => {
	const intl = useIntl()
  const {cop} = useIntlCurrencyFormat()

	const UIContext = useFactoryWalletUIContext()
	const UIProps = useMemo(
		() => ({
			valuesTable: UIContext?.valuesTable ?? initialValues,
			queryParams: UIContext?.queryParams ?? initialFilter,
			tableLoading: UIContext?.tableLoading ?? true,
			setQueryParams: UIContext?.setQueryParams,
			openEditDialog: UIContext?.openEditDialog ?? ((id: number | null) => { }),
			openDeleteDialog: UIContext?.openDeleteDialog ?? ((id: number | null) => { }),
		}),
		[UIContext]
	)

	const {
		totalCount,
		data: { entities },
	} = UIProps.valuesTable
	const paginationOptions = useMemo(
		() => ({
			custom: true,
			totalSize: totalCount,
			sizePerPageList: [...uiHelpers.sizePerPageList, { text: `${totalCount}`, value: totalCount }],
			sizePerPage: UIProps.queryParams.pageSize,
			page: UIProps.queryParams.pageNumber,
		}),
		[
			UIProps.queryParams.pageSize,
			UIProps.queryParams.pageNumber,
			uiHelpers.sizePerPageList,
			totalCount,
		]
	)

	const columns = useMemo(
		() => [
			{
				dataField: 'name',
				text: 'Fabrica',
				sort: false,
				sortCaret,
				headerSortingClasses,
			},
			{
				dataField: 'advance',
				text: 'Anticipo',
				sort: false,
				sortCaret,
				headerSortingClasses,
				formatter: (cellContent: number) => cop.format(cellContent),
			},
			{
				dataField: 'payment',
				text: 'Abono',
				sort: false,
				sortCaret,
				headerSortingClasses,
				formatter: (cellContent: number) => cop.format(cellContent),
			},
			{
				dataField: 'balance',
				text: 'Saldo',
				sort: false,
				sortCaret,
				headerSortingClasses,
				formatter: (cellContent: number) => cop.format(cellContent),
			},
		],
		[]
	)
	return (
		<PaginationProvider pagination={paginationFactory(paginationOptions)}>
			{({ paginationProps, paginationTableProps }) => {
				return (
					<Pagination isLoading={UIProps.tableLoading} paginationProps={paginationProps}>
						<BootstrapTable
							wrapperClasses='table-responsive'
							bordered={false}
							classes='table table-head-custom table-vertical-center overflow-hidden'
							bootstrap4
							remote
							{...paginationTableProps}
							keyField='id'
							data={entities}
							columns={columns}
							defaultSorted={uiHelpers.defaultSorted}
							onTableChange={getHandlerTableChange(UIProps.setQueryParams)}
						// selectRow={getSelectRow({
						//   entities,
						//   ids: ordersUIProps.ids,
						//   setIds: ordersUIProps.setIds,
						// })}
						>
							<PleaseWaitMessage entities={entities} />
							<NoRecordsFoundMessage entities={entities} />
						</BootstrapTable>
					</Pagination>
				)
			}}
		</PaginationProvider>
	)
}

export default ReferencesTable
