import type {QueryParams, FactoryBalanceModel} from '../models'
import axios from 'axios'

const useBalanceByFactory = (queryParams: QueryParams) => {
  return async () => {
    try {
			if (!queryParams.factoryId) return [];
      const response = await axios.get<FactoryBalanceModel[]>(
        `${process.env.REACT_APP_API_URL}/factory/getBalanceByFactoryV2/${queryParams.factoryId}`,
        {
          params: {
            start: queryParams.start ?? undefined,
            end: queryParams.end ?? undefined,
          },
        }
      )
      return response.data
    } catch (error) {
      console.log(error)
      return []
    }
	}
}

export default useBalanceByFactory
