import React, {FC, useEffect, useMemo} from 'react'
import {Modal} from 'react-bootstrap'
import DialogHeader from './DialogHeader'
import {name_factoryId, useReferencesUIContext} from '../ReferencesUIContext'
import {ReferencesModel} from '../models'
import EditForm from './EditForm'
import axios from 'axios'
import Swal from 'sweetalert2'

interface EditDialogProps {
  id: name_factoryId | null
  show: boolean
  onHide: () => void
}

const EditDialog: FC<EditDialogProps> = ({id, show, onHide}) => {
  const UIContext = useReferencesUIContext()
  const UIProps = useMemo(
    () => ({
      select: UIContext?.select ?? (async (id: name_factoryId | null) => {}),
      selected: UIContext?.selected ?? null,
      isLoadingSelected: UIContext?.isLoadingSelected ?? true,
			loadTable: UIContext?.loadTable,
    }),
    [UIContext]
  )

  const save = async (values: ReferencesModel) => {
    try {
      if (id === null) {
        await axios.post(`${process.env.REACT_APP_API_URL}/references`, values)
        Swal.fire({
          icon: 'success',
          title: 'Referencia creada correctamente',
        })
      } else {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/references/${id.name}/${id.factoryId}`,
          values
        )
        Swal.fire({
          icon: 'success',
          title: 'Referencia actualizada correctamente',
        })
      }
			UIProps.loadTable?.()
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
      })
    } finally {
      onHide()
    }
  }

  useEffect(() => {
    UIProps.select(id)
  }, [id])

  return (
    <Modal
      size='lg'
      show={show}
      onHide={() => {
        UIProps.select(null)
        onHide()
      }}
      aria-labelledby='example-modal-sizes-title-lg'
			backdrop="static"
    >
      <DialogHeader id={id ?? undefined} />
      <EditForm
        save={save}
        loadingData={UIProps.isLoadingSelected}
        data={UIProps.selected}
        onHide={onHide}
      />
    </Modal>
  )
}

export default EditDialog
