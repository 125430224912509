import {useMemo} from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {PaginationProvider} from 'react-bootstrap-table2-paginator'
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from '../../../../../../_metronic/helpers'
import {Pagination} from '../../../../../../_metronic/partials/control'
import {useIntl} from 'react-intl'
import * as columnFormatters from './column-formatters'

import * as uiHelpers from '../ReferencesUIHelpers'
import {name_factoryId, useReferencesUIContext} from '../ReferencesUIContext'
import {initialFilter, initialValues} from '../../billing/BillingsUIHelpers'
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat'
import moment from 'moment'

const ReferencesTable = () => {
  const intl = useIntl()
  const {thousand} = useIntlCurrencyFormat()

  const UIContext = useReferencesUIContext()
  const UIProps = useMemo(
    () => ({
      valuesTable: UIContext?.valuesTable ?? initialValues,
      queryParams: UIContext?.queryParams ?? initialFilter,
      tableLoading: UIContext?.tableLoading ?? true,
      setQueryParams: UIContext?.setQueryParams,
      openEditDialog: UIContext?.openEditDialog ?? ((id: name_factoryId | null) => {}),
      openDeleteDialog: UIContext?.openDeleteDialog ?? ((id: name_factoryId | null) => {}),
    }),
    [UIContext]
  )

  const {
    totalCount,
    data: {entities},
  } = UIProps.valuesTable
  const paginationOptions = useMemo(
    () => ({
      custom: true,
      totalSize: totalCount,
      sizePerPageList: [...uiHelpers.sizePerPageList, {text: `${totalCount}`, value: totalCount}],
      sizePerPage: UIProps.queryParams.pageSize,
      page: UIProps.queryParams.pageNumber,
    }),
    [
      UIProps.queryParams.pageSize,
      UIProps.queryParams.pageNumber,
      uiHelpers.sizePerPageList,
      totalCount,
    ]
  )

  const columns = useMemo(
    () => [
			{
				dataField: "name",
				text: "Referencia",
				sort: false,
				sortCaret,
				headerSortingClasses,
			},
			{
				dataField: "quantity",
				text: "Cantidad",
				sort: false,
				sortCaret,
				headerSortingClasses,
				formatter: (cellContent: number) => thousand?.format(cellContent)
			},
			{
				dataField: "factory.name",
				text: "Fabrica",
				sort: false,
				sortCaret,
				headerSortingClasses,
			},
			{
				dataField: "createdAt",
				text: "Fecha",
				sort: false,
				sortCaret,
				headerSortingClasses,
				formatter: (cellContent: string) => moment(cellContent).format("LL")
			},
      {
        dataField: 'action',
        // text: intl.formatMessage({ id: "FORMULARIES.ACTIONS"}),
        text: intl.formatMessage({id: 'GENERAL.ACTIONS'}),
        formatter: columnFormatters.ActionsColumnFormatter,
        formatExtraData: {
          openEditDialog: UIProps.openEditDialog,
          openDeleteDialog: UIProps.openDeleteDialog,
          intl,
        },
        classes: 'text-right pr-0',
        headerClasses: 'text-right pr-3',
        style: {
          minWidth: '100px',
        },
      },
    ],
    []
  )
  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({paginationProps, paginationTableProps}) => {
        return (
          <Pagination isLoading={UIProps.tableLoading} paginationProps={paginationProps}>
            <BootstrapTable
              wrapperClasses='table-responsive'
              bordered={false}
              classes='table table-head-custom table-vertical-center overflow-hidden'
              bootstrap4
              remote
              {...paginationTableProps}
              keyField='id'
              data={entities.map(e => ({...e, id: `${e.name}${e.factoryId}`}))}
              columns={columns}
              defaultSorted={uiHelpers.defaultSorted}
              onTableChange={getHandlerTableChange(UIProps.setQueryParams)}
              // selectRow={getSelectRow({
              //   entities,
              //   ids: ordersUIProps.ids,
              //   setIds: ordersUIProps.setIds,
              // })}
            >
              <PleaseWaitMessage entities={entities} />
              <NoRecordsFoundMessage entities={entities} />
            </BootstrapTable>
          </Pagination>
        )
      }}
    </PaginationProvider>
  )
}

export default ReferencesTable
