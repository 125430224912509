import React, {useMemo, useState} from 'react'
import {Formik} from 'formik'
import {isEqual} from 'lodash'
import {useReferencesUIContext} from '../ReferencesUIContext'
import {useIntl} from 'react-intl'
import useDebounceFnc from '../../../../../../utils/customHooks/useDebounceFnc'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { useMeridiemMode } from '@mui/lab/internal/pickers/hooks/date-helpers-hooks'

interface filterInterface {
	end?: string;
	start?: string;
	name: string;
	factoryId?: number
}
interface filterValues {
	end: string;
	start: string;
	searchText: string;
	factoryId: string;
}
const prepareFilter = (queryParams: any, values: filterValues) => {
  const {end, start, searchText, factoryId} = values

  const newQueryParams = {...queryParams}
  const filter: filterInterface = {
		name: '',
		factoryId: undefined,
		end: undefined,
		start: undefined,
  }

  //Filter by reference
  if (searchText) {
    filter.name = searchText
  }

	if (end) {
		filter.end = end;
	}

	if (start) {
		filter.start = start;
	}

	if (factoryId) {
		filter.factoryId = Number(factoryId);
	}

  newQueryParams.filter = filter
  return newQueryParams
}

// export interface PurchasesFilterProp {
//   listLoading: boolean
// }
const ReferencesFilter: React.FC<{}> = () => {
  // Purchases UI Context
  const intl = useIntl()
  const UIContext = useReferencesUIContext();
  const UIProps = useMemo(() => {
    return {
      queryParams: UIContext?.queryParams,
      setQueryParams: UIContext?.setQueryParams,
			factories: UIContext?.factories ?? [],
    }
  }, [UIContext])
  const [start, setStart] = useState<Date | null>(null)
  const [end, setEnd] = useState<Date | null>(null)

  // queryParams, setQueryParams,
  const applyFilter = useDebounceFnc((values: filterValues) => {
    const newQueryParams = prepareFilter(UIProps.queryParams, values)
    if (!isEqual(newQueryParams, UIProps.queryParams)) {
      newQueryParams.pageNumber = 1
      // update list by queryParams
      UIProps.setQueryParams?.(newQueryParams)
    }
  }, 400)

  return (
    <>
      <Formik
        initialValues={{
					searchText: '',
					factoryId: '',
					start: '',
					end: '',
        }}
        onSubmit={(values) => {
          applyFilter(values)
        }}
      >
        {({values, handleSubmit, handleBlur, handleChange, setFieldValue}) => (
          <form onSubmit={handleSubmit} className='form form-label-right'>
            <div className='form-group row'>
              {/* FILTRAR POR FABRICA */}
              <div className="col-lg-3 mb-2">
                <select
                  className="form-control"
                  name="factoryId"
                  placeholder={intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_FACTORY"})}
                  onChange={(e) => {
                    setFieldValue("factoryId", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.factoryId}
                >
                  <option value={""}>{intl.formatMessage({id:"LABELS.FILTER.CHOOSE_FACTORY"})}</option>
                  {UIProps.factories.map((f) => (
                    <option value={f.id} key={f.id}>{f.name}</option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  {intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_FACTORY"})}
                </small>
              </div>

              <div className='col-lg-3'>
                <input
                  type='text'
                  className='form-control'
                  name='searchText'
                  placeholder={intl.formatMessage({id: 'LABELS.FILTER.SEARCH'})}
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue('searchText', e.target.value)
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>
                  {intl.formatMessage({id: 'LABELS.FILTER.SEARCH_BY_REFERENCE'})}
                </small>
              </div>

              {/* FILTRAR POR START_DATE */}
              <div className='col-lg-3 mb-2'>
                <DatePicker
                  className='form-control'
                  selected={start}
                  isClearable
                  onChange={(date) => {
                    setStart(date)
                    if (date === null) {
                      setFieldValue('start', '')
                      handleSubmit()
                      return
                    }
                    setFieldValue(
                      'start',
                      moment(date).format('YYYY-MM-DD')
                    )
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>Filtrar por fecha de inicio</small>
              </div>

              {/* FILTRAR POR END_DATE */}
              <div className='col-lg-3 mb-2'>
                <DatePicker
                  className='form-control'
                  selected={end}
                  isClearable
                  onChange={(date) => {
                    setEnd(date)
                    if (date === null) {
                      setFieldValue('end', '');
                      handleSubmit()
                      return
                    }
                    setFieldValue(
                      'end',
                      moment(date).format('YYYY-MM-DD')
                    )
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>Filtrar por fecha final</small>
              </div>

            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default ReferencesFilter;
