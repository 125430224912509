// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {IntlShape} from 'react-intl'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

interface ActionActionProps {
  openEditDialog: any
  openDeleteDialog: any
  intl: IntlShape
}

export const ActionsColumnFormatter = (
  _cellContent: any,
  row: any,
  _rowIndex: any,
  {openEditDialog, openDeleteDialog, intl}: ActionActionProps
) => {
  return (
    <>
      {/* EDITAR */}
      <OverlayTrigger
        placement='top'
        delay={{
          show: 500,
          hide: 200,
        }}
        overlay={<Tooltip id='tooltip'>{intl.formatMessage({id: 'GENERAL.EDIT_LABEL'})}</Tooltip>}
      >
        <button
          data-tip
          data-for='editTip'
          className='btn btn-icon btn-primary btn-sm m-1'
          onClick={() => openEditDialog({name: row.name, factoryId: row.factory.id})}
        >
          <span className='svg-icon svg-icon-md svg-icon-primary'>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
            {/* <Visibility /> */}
          </span>
        </button>
      </OverlayTrigger>

      {/* ELIMINAR */}
      <OverlayTrigger
        placement='top'
        delay={{
          show: 500,
          hide: 200,
        }}
        overlay={<Tooltip id='tooltip'>{intl.formatMessage({id: 'GENERAL.DELETE_LABEL'})}</Tooltip>}
      >
        <button
          data-tip
          data-for={`fullDelete`}
          className={`btn btn-danger btn-icon btn-sm m-1`}
          onClick={() => openDeleteDialog({name: row.name, factoryId: row.factory.id})}
        >
          <span className={`svg-icon svg-icon-md svg-icon-danger`}>
            <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
          </span>
        </button>
      </OverlayTrigger>
    </>
  )
}
