import React, {FC, useEffect, useMemo} from 'react'
import {Modal} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {useSupplyProjectionUIContext} from '../SupplyProjectionUIContext'
import LoadingDialog from '../loading-dialog'
import axios from 'axios'

export interface DeleteDialogProps {
  id?: number | null
  show: boolean
  onHide: () => void
}
const DeleteDialog: FC<DeleteDialogProps> = ({id, show, onHide}) => {
  const UIContext = useSupplyProjectionUIContext()
  const UIProps = useMemo(
    () => ({
      setIsLoading: UIContext?.setIsLoading,
      loadTable: UIContext?.loadTable,
      isLoadinSelected: UIContext?.isLoadingSelected ?? true,
      selected: UIContext?.selected,
      selectedName: UIContext?.selectedName ?? '',
      select: UIContext?.select,
    }),
    []
  )
  const destroy = async () => {
    try {
			if (!id) return
      UIProps.setIsLoading?.(true)

			const result = await axios.delete(
				`${process.env.REACT_APP_API_URL}/supplyProjection/${id}`
			)

			if (result.data?.success) {
				Swal.fire({
					title: 'eliminado correctamente',
					icon: 'success',
				})
			}
      await UIProps.loadTable?.()
    } catch (error) {
      console.log(error)

      await Swal.fire({
        title: 'Error al eliminar proyección de insumos',
        icon: 'error',
      })
    } finally {
      UIProps.setIsLoading?.(false)
      onHide()
    }
  }

  useEffect(() => {
    if (!id) {
      onHide()
    } else {
      UIProps.select?.(id)
    }
  }, [id, UIProps.select])

  return (
    <Modal show={show} onHide={onHide} aria-labelledby='example-modal-sizes-title-lg'>
      <Modal.Header closeButton>
        <Modal.Title id='example-modal-sizes-title-lg'>
          {UIProps.isLoadinSelected
            ? 'Cargando...'
            : `
           "Eliminar" 
           ${UIProps.selectedName}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {UIProps.isLoadinSelected ? (
          <LoadingDialog color={'primary'} />
        ) : (
          <span>{'¿Está seguro que desea eliminar?'}</span>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!UIProps.isLoadinSelected && (
          <div>
            <button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
              Cancel
            </button>
            <> </>
            <button type='button' onClick={destroy} className={`btn btn-danger btn-elevate`}>
              {'Eliminar'}
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteDialog
