import React, {useEffect, useState} from 'react'
import {ArrayHelpers, Field, FieldArray, FormikProps, ErrorMessage} from 'formik'
import axios from 'axios'
import {SupplyModel} from '../../../../admin/pages/supplies/models'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {AutoCompleteInput} from '../../../../../../_metronic/partials/control/forms/AutoComplete'
import {Input} from '../../../../../../_metronic/partials/control'
import SVG from 'react-inlinesvg'
import CurrencyInput from 'react-currency-input-field'
import {getFieldCSSClasses} from '../../../../../../utils'
import {ReferencesSupplies} from '../models'

interface Values {
  name: string
  factoryId: string
  description: string
  costManufact: string
  profit: string
  investorProfit: string
  referencesSupplies: ReferencesSupplies[]
}

type RenderProps = ArrayHelpers & {
  form: FormikProps<Values>
  name: string
}

const SuppliesTable = () => {
  const [supply, setSupply] = useState<SupplyModel | null>(null)
  const [supplies, setSupplies] = useState<SupplyModel[]>([])
  const [quantity, setQuantity] = useState<string | undefined>('')

  useEffect(() => {
    const token = axios.CancelToken.source()
    ;(async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/supply`, {
          cancelToken: token.token,
        })
        setSupplies(res.data)
      } catch {}
    })()
    return () => {
      token.cancel()
    }
  }, [])

  return (
    <FieldArray name='referencesSupplies'>
      {({form, push, remove}: RenderProps) => (
        <div className='px-4' style={{outline: '1px solid #dde', borderRadius: '0.5rem'}}>
          <div className='row pt-4'>
            <div className='col-12'>
              <h4>Agregar Insumos</h4>
            </div>
            <div className='col-lg-4'>
              <label>
                <b>Insumos</b>
              </label>
              <Field
                name='document'
                component={AutoCompleteInput}
                options={supplies.filter((sup) =>
                  form.values.referencesSupplies.every((rs) => rs.supplyId !== sup.id)
                )}
                value={supply}
                onChange={(e: any, value: SupplyModel | null) => {
                  setSupply(value)
                }}
                getOptionLabel={(option: SupplyModel) => `${option.name}-${option.reference} `}
              />
            </div>
            <div className='col-lg-4'>
              <label>
                <b>Cantidad</b>
              </label>
              <CurrencyInput
                disableGroupSeparators
                id='quantity'
                name='quantity'
                onValueChange={(value) => {
                  setQuantity(value)
                }}
                onKeyDown={(e) => {
                  if (e.key !== 'Enter') return
                  e.preventDefault()
                  if (supply === null || Number(quantity) <= 0) return
                  setSupply(null)
                  setQuantity('')
                  push({supplyId: supply?.id, quantity: Number(quantity)})
                }}
                allowDecimals
								decimalsLimit={3}
                groupSeparator=','
                decimalSeparator='.'
                className='form-control'
                allowNegativeValue={false}
                value={quantity}
              />
              <small>
                Unidad: <span style={{textTransform: 'capitalize'}}>{supply?.unit?.name}</span>
              </small>
            </div>
            <div className='col-lg-4 mt-6'>
              <button
                className='btn btn-primary btn-elevate btn-block'
                onClick={() => {
                  setSupply(null)
                  setQuantity('')
                  push({supplyId: supply?.id, quantity: Number(quantity)})
                }}
                type='button'
                disabled={supply === null || Number(quantity) <= 0}
              >
                <i className='fas fa-plus'></i>
                {'Agregar'}
              </button>
            </div>
          </div>
          <div className='form-group row' style={{overflowX: 'scroll'}}>
            <div>
              <table className='styled-table-success'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Insumo</th>
                    <th>Referencia</th>
                    <th>Cantidad</th>
                    <th>Unidad</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {form.values.referencesSupplies.map((item, index) => {
                    const sup = supplies.find((s) => s.id === item.supplyId)
                    const qtyMeta = form.getFieldMeta(`referencesSupplies.${index}.quantity`)
                    return (
                      <tr key={item.supplyId}>
                        <td>{index + 1}</td>
                        <td>{sup?.name}</td>
                        <td>{sup?.reference}</td>
                        <td>
                          <CurrencyInput
                            disableGroupSeparators
                            id={`referencesSupplies.${index}.quantity`}
                            name={`referencesSupplies.${index}.quantity`}
                            onValueChange={(value) => {
                              form.setFieldValue(`referencesSupplies.${index}.quantity`, value)
                            }}
                            allowDecimals
                            className={getFieldCSSClasses(qtyMeta)}
                            groupSeparator=','
                            decimalSeparator='.'
														decimalsLimit={3}
                            onBlur={() =>
                              form.setFieldTouched(`referencesSupplies.${index}.quantity`)
                            }
                            allowNegativeValue={false}
                            value={form.values.referencesSupplies[index]?.quantity}
                          />
                          {qtyMeta.touched && qtyMeta.error && (
                            <small className='text-danger'>{qtyMeta.error}</small>
                          )}
                        </td>
                        <td>{sup?.unit?.name}</td>
                        <td>
                          <a
                            className='btn btn-icon btn-danger btn-hover-danger btn-sm mx-1 '
                            onClick={() => remove(index)}
                          >
                            <span className='svg-icon svg-icon-md svg-icon-danger'>
                              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Trash.svg')} />
                            </span>
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                  {form.values.referencesSupplies.length === 0 ? (
                    <tr>
                      <td colSpan={6} style={{textAlign: 'center'}}>
                        <h4 className='m-2'>Sin Insumos</h4>
                        {form.touched.referencesSupplies &&
                          form.errors.referencesSupplies &&
                          !Array.isArray(form.errors.referencesSupplies) && (
                            <div className='text-danger'>{form.errors.referencesSupplies}</div>
                          )}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </FieldArray>
  )
}

export default SuppliesTable
