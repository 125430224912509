export function reverseFormatNumber(val: string,locale:string){
    val = val.split('$').length > 0 ? val.split('$')[1] : val;
    let group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
    let decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
    let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
    reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
    return Number.isNaN(reversedVal)?"0":reversedVal;
}

export function getFieldCSSClasses(meta: any, force?: boolean) {
	const classes = ['form-control']
	if ((meta.touched || force) && (meta.errors || meta.error)) {
		classes.push('is-invalid')
	}

	if ((meta.touched || force) && !(meta.errors || meta.error)) {
		classes.push('is-valid')
	}

	return classes.join(' ')
}
