import React from 'react'
import ExelJS, { TableColumnProperties } from 'exceljs'

interface Data {
	supplyId: number
	name: string
	reference: string
	quantity: number
	currentQuantity: number
	pendingQuantity: number
	unit: string
}

const SHEET_NAME = 'Ganancias'
const COLUMNS: TableColumnProperties[] = [
	{ name: 'Insumo', filterButton: true },
	{ name: 'Referencia', filterButton: true },
	{ name: 'Cantidad proyectada', totalsRowFunction: 'sum' },
	{ name: 'Cantidad en stock', totalsRowFunction: 'sum' },
	{ name: 'Cantidad pendiente', totalsRowFunction: 'sum' },
	{ name: 'Unidad', filterButton: true },
]

const useGenerateExel = () => {
	const createExel = async (data: Data[]) => {
		const wb = new ExelJS.Workbook()
		const sheet = wb.addWorksheet(SHEET_NAME, {
			views: [{ state: 'frozen', ySplit: 1 }],
		})

		sheet.getColumn('A').width = 20
		sheet.getColumn('B').width = 20
		sheet.getColumn('C').width = 20
		sheet.getColumn('D').width = 20
		sheet.getColumn('E').width = 20
		sheet.getColumn('F').width = 20

		const A1 = sheet.getCell('A1')
		console.log(A1)
		A1.value = `Insumos Requeridos`
		A1.font = {
			bold: true,
			size: 24,
		}

		sheet.addTable({
			name: 'Inventario',
			ref: 'A3',
			headerRow: true,
			totalsRow: true,
			columns: COLUMNS,
			style: {
				theme: 'TableStyleLight1',
				showRowStripes: true,
			},
			rows: data.map((d) => [
				d.name,
				d.reference,
				d.quantity,
				d.currentQuantity,
				d.pendingQuantity,
				d.unit,
			]),
		})

		return wb.xlsx.writeBuffer()
	}

	const download = async (data: Data[]) => {
		try {
			const buffer = await createExel(data)
			const content = new Blob([buffer])

			const uri = window.URL.createObjectURL(content)
			const link = document.createElement('a')

			link.setAttribute('href', uri)
			link.setAttribute('download', 'Proyección de insumos.xlsx')

			link.click()
		} catch (error) {
			console.log(error)
		}
	}

	return download
}

export default useGenerateExel
