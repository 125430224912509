import React, { FC, useEffect, useMemo, useState } from 'react'
import { ArrayHelpers, Field, FieldArray, FormikProps, ErrorMessage } from 'formik'
import axios from 'axios'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { AutoCompleteInput } from '../../../../../../_metronic/partials/control/forms/AutoComplete'
import { Input } from '../../../../../../_metronic/partials/control'
import SVG from 'react-inlinesvg'
import CurrencyInput from 'react-currency-input-field'
import { getFieldCSSClasses } from '../../../../../../utils'
import { ReferencesModel } from '../../references/models'
import { SupplyProjectionReferences } from '../models'
import { useSupplyProjectionUIContext } from '../SupplyProjectionUIContext'

interface Values {
	factoryId: string
	supplyProjectionReferences: SupplyProjectionReferences[]
}

type RenderProps = ArrayHelpers & {
	form: FormikProps<Values>
	name: string
}

interface ReferencesTableProps {
	factoryId?: number | string
}

const ReferencesTable: FC<ReferencesTableProps> = ({ factoryId }) => {
	const [reference, setReference] = useState<ReferencesModel | null>(null)
	const [quantity, setQuantity] = useState<string | undefined>('')

	const UIContext = useSupplyProjectionUIContext()
	const UIProps = useMemo(
		() => ({
			references: UIContext?.references ?? [],
			loadReferences: UIContext?.loadReferences,
		}),
		[UIContext]
	)

	useEffect(() => {
		const token = axios.CancelToken.source()
		UIProps.loadReferences?.(factoryId, token.token)
		return () => {
			setReference(null)
			token.cancel()
		}
	}, [factoryId])

	return (
		<FieldArray name='supplyProjectionReferences'>
			{({ form, push, remove }: RenderProps) => (
				<div className='px-4' style={{ outline: '1px solid #dde', borderRadius: '0.5rem' }}>
					<div className='row pt-4'>
						<div className='col-12'>
							<h4>Agregar Referencias</h4>
						</div>
						<div className='col-lg-4'>
							<label>
								<b>Rerefencias</b>
							</label>
							<Field
								name='document'
								component={AutoCompleteInput}
								options={UIProps.references.filter((ref) =>
									form.values.supplyProjectionReferences.every((spr) => 
										spr.name !== ref.name || spr.factoryId !== ref.factoryId
									)
								)}
								value={reference}
								label='Referencias'
								onChange={(_e: any, value: ReferencesModel | null) => {
									setReference(value)
								}}
								getOptionLabel={(option: ReferencesModel) => option.name}
								disabled={!factoryId}
							/>
						</div>
						<div className='col-lg-4'>
							<label>
								<b>Cantidad</b>
							</label>
							<CurrencyInput
								disableGroupSeparators
								id='quantity'
								name='quantity'
								onValueChange={(value) => {
									setQuantity(value)
								}}
								onKeyDown={(e) => {
									if (e.key !== 'Enter') return
									e.preventDefault()
									if (reference === null || Number(quantity) <= 0) return
									setReference(null)
									setQuantity('')
									push({
										factoryId: reference.factoryId,
										name: reference.name,
										quantity: Number(quantity),
									})
								}}
								allowDecimals={false}
								className='form-control'
								allowNegativeValue={false}
								value={quantity}
								disabled={!factoryId}
							/>
						</div>
						<div className='col-lg-4 mt-6'>
							<button
								className='btn btn-primary btn-elevate btn-block'
								onClick={() => {
									setReference(null)
									setQuantity('')
									push({
										factoryId: reference?.factoryId,
										name: reference?.name,
										quantity: Number(quantity),
									})
								}}
								type='button'
								disabled={reference === null || Number(quantity) <= 0}
							>
								<i className='fas fa-plus'></i>
								{'Agregar'}
							</button>
						</div>
					</div>
					<div className='form-group row' style={{ overflowX: 'scroll' }}>
						<div>
							<table className='styled-table-success'>
								<thead>
									<tr>
										<th>#</th>
										<th>{'Referencia'}</th>
										<th>{'Cantidad'}</th>
										<th>{'Acción'}</th>
									</tr>
								</thead>
								<tbody>
									{form.values.supplyProjectionReferences.map((item, index) => {
										const qtyMeta = form.getFieldMeta(
											`supplyProjectionReferences.${index}.quantity`
										)
										console.log(index, qtyMeta);
										return (
											<tr key={`${item.name}-${item.factoryId}`}>
												<td>{index + 1}</td>
												<td>{item.name}</td>
												<td>
													<CurrencyInput
														disableGroupSeparators
														id={`supplyProjectionReferences.${index}.quantity`}
														name={`supplyProjectionReferences.${index}.quantity`}
														onValueChange={(value) => {
															form.setFieldValue(
																`supplyProjectionReferences.${index}.quantity`,
																value
															)
														}}
														allowDecimals
														className={getFieldCSSClasses(qtyMeta, true)}
														onBlur={() =>
															form.setFieldTouched(`supplyProjectionReferences.${index}.quantity`)
														}
														allowNegativeValue={false}
														value={form.values.supplyProjectionReferences[index]?.quantity}
													/>
													{qtyMeta.error && (
														<small className='text-danger'>{qtyMeta.error}</small>
													)}
												</td>
												<td>
													<a
														className='btn btn-icon btn-danger btn-hover-danger btn-sm mx-1 '
														onClick={() => remove(index)}
													>
														<span className='svg-icon svg-icon-md svg-icon-danger'>
															<SVG src={toAbsoluteUrl('/media/svg/icons/Home/Trash.svg')} />
														</span>
													</a>
												</td>
											</tr>
										)
									})}
									{form.values.supplyProjectionReferences.length === 0 ? (
										<tr>
											<td colSpan={6} style={{ textAlign: 'center' }}>
												<h4 className='m-2'>Sin Referencias</h4>
												{form.touched.supplyProjectionReferences &&
													form.errors.supplyProjectionReferences &&
													!Array.isArray(form.errors.supplyProjectionReferences) && (
														<div className='text-danger'>
															{form.errors.supplyProjectionReferences}
														</div>
													)}
											</td>
										</tr>
									) : null}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)}
		</FieldArray>
	)
}

export default ReferencesTable
