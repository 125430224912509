import {Form, Formik, Field} from 'formik'
import React, {FC, useMemo, useState} from 'react'
import {Col, Modal, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {ReferencesModel} from '../models'
import * as Yup from 'yup'
import LoadingDialog from '../loading-dialog'
import SuppliesTable from './SuppliesTable'
import {Input, Select} from '../../../../../../_metronic/partials/control'
import {useReferencesUIContext} from '../ReferencesUIContext'
import TextAreaInput from '../../../../../../_metronic/partials/control/forms/TextAreaInput'
import CurrencyInput from 'react-currency-input-field'
import {Description} from '@mui/icons-material'
import {getFieldCSSClasses} from '../../../../../../utils'
import { speedDialIconClasses } from '@mui/material'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Requerido'),
  factoryId: Yup.number().required('Requerido'),
  description: Yup.string(),
  costManufact: Yup.number(),
  profit: Yup.number(),
  investorProfit: Yup.number(),
  referencesSupplies: Yup.array()
    .min(1, 'Ingrese al menos un insumo')
    .of(
      Yup.object().shape({
        supplyId: Yup.number().min(1, 'Requerido').required('Requerido'),
        quantity: Yup.number().min(0.001, 'Requerido').required('Requerido'),
      })
    ),
})

interface EditFormProps {
  save: (values: ReferencesModel) => Promise<void>
  loadingData: boolean
  data: ReferencesModel | null
  onHide: () => void
}

const EditForm: FC<EditFormProps> = ({save, loadingData, data, onHide}) => {
  const intl = useIntl()
  const UIContext = useReferencesUIContext()
  const UIProps = useMemo(
    () => ({
      factories: UIContext?.factories ?? [],
    }),
    [UIContext]
  )
	const [loading, setLoading] = useState(false);

  if (loadingData || loading) return <LoadingDialog color='primary' />

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: data?.name ?? '',
        factoryId: data?.factoryId?.toString() ?? '',
        description: data?.description ?? '',
        costManufact: data?.costManufact?.toString() ?? '',
        profit: data?.profit?.toString() ?? '',
        investorProfit: data?.investorProfit?.toString() ?? '',
        referencesSupplies: data?.referencesSupplies ?? [],
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        try {
					setLoading(true)
          await save({
            name: values.name,
            factoryId: Number(values.factoryId),
            description: values.description ? values.description : undefined,
            costManufact: values.costManufact ? Number(values.costManufact) : undefined,
            profit: values.profit ? Number(values.profit) : undefined,
            investorProfit: values.investorProfit ? Number(values.investorProfit) : undefined,
            referencesSupplies: values.referencesSupplies.map((rs) => ({
              supplyId: rs.supplyId,
              quantity: Number(rs.quantity),
            })),
          })
        } catch {}
				finally {
					setLoading(false);
				}
      }}
    >
      {({setFieldValue, values, getFieldMeta, setFieldTouched}) => (
        <Form>
          <Modal.Body>
            <Row>
              <Col md='6' className='mb-4'>
                <Field
                  name='name'
                  component={Input}
                  placeholder={'Referencia'}
                  type='text'
                  disabled={data !== null}
                  label={intl.formatMessage({id: 'ORDER.REFERENCE'})}
                />
              </Col>
              <Col md='6' className='mb-4'>
                <Field
                  name='factoryId'
                  label='Fabrica'
                  disabled={data !== null}
                  component={Select}
                  onChange={(e: any) => {
                    setFieldValue('factoryId', e.target.value)
                  }}
                  value={values.factoryId}
                >
                  <option value={0}>{'Escoja una fabrica'}</option>
                  {UIProps.factories.map((f, index) => (
                    <option value={f.id} key={index}>
                      {f.name}
                    </option>
                  ))}
                </Field>
              </Col>
              <Col md='12' className='mb-4'>
                <Field
                  name='description'
                  component={TextAreaInput}
                  placeholder={intl.formatMessage({id: 'GENERAL.DESCRIPTION'})}
                  label={intl.formatMessage({id: 'GENERAL.DESCRIPTION'})}
                />
              </Col>

              <Col md='6' className='mb-4'>
                <label>
                  <b>Costo de manufactura</b>
                </label>
                <CurrencyInput
                  disableGroupSeparators
                  id='costManufact'
                  name='costManufact'
                  onValueChange={(value) => {
                    setFieldValue('costManufact', value)
                  }}
                  allowDecimals
                  prefix='$ '
									groupSeparator=','
									decimalSeparator='.'
                  className={getFieldCSSClasses(getFieldMeta('costManufact'))}
                  onBlur={() => setFieldTouched('costManufact')}
                  allowNegativeValue={false}
                  value={values.costManufact}
                />
              </Col>
              <Col md='6' className='mb-4'>
                <label>
                  <b>Ganancias</b>
                </label>
                <CurrencyInput
                  disableGroupSeparators
                  id='profit'
                  name='profit'
                  onValueChange={(value) => {
                    setFieldValue('profit', value)
                  }}
                  allowDecimals
                  prefix='$ '
									groupSeparator=','
									decimalSeparator='.'
                  className={getFieldCSSClasses(getFieldMeta('profit'))}
                  onBlur={() => setFieldTouched('profit')}
                  allowNegativeValue={false}
                  value={values.profit}
                />
              </Col>
              <Col md='6' className='mb-4'>
                <label>
                  <b>Ganancias por inversionistas</b>
                </label>
                <CurrencyInput
                  disableGroupSeparators
                  id='investorProfit'
                  name='investorProfit'
									groupSeparator=','
									decimalSeparator='.'
                  onValueChange={(value) => {
                    setFieldValue('investorProfit', value)
                  }}
                  allowDecimals
                  prefix='$ '
                  className={getFieldCSSClasses(getFieldMeta('investorProfit'))}
                  onBlur={() => setFieldTouched('investorProfit')}
                  allowNegativeValue={false}
                  value={values.investorProfit}
                />
              </Col>
            </Row>
            <SuppliesTable />
          </Modal.Body>
          <Modal.Footer>
            <button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
              {intl.formatMessage({id: 'BUTTON.CANCEL'})}
            </button>
            <button type='submit' className='btn btn-primary btn-elevate'>
              {intl.formatMessage({id: 'BUTTON.SAVE'})}
            </button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default EditForm
