
import React from 'react'
import ExelJS, {TableColumnProperties} from 'exceljs'
import {inventorySupply} from '../InventoryUIContext'

const SHEET_NAME = 'Ganancias'
const COLUMNS: TableColumnProperties[] = [
  {name: 'Insumo', filterButton: true, totalsRowLabel: 'Totales:'},
  {name: 'Cantidad comprada', totalsRowFunction: 'sum'},
  {name: 'Cantidad utilizada', totalsRowFunction: 'sum'},
  {name: 'Cantidad actual', totalsRowFunction: 'sum'},
  {name: 'Precio unitario', totalsRowFunction: 'sum'},
  {name: 'Precio total', totalsRowFunction: 'sum'},
  {name: 'Unidad', filterButton: true},
]

const useGenerateExel = (getData: () => Promise<inventorySupply[]>, factoryName: string) => {
  const createExel = async () => {
    const wb = new ExelJS.Workbook()
    const sheet = wb.addWorksheet(SHEET_NAME, {
      views: [{state: 'frozen', ySplit: 1}],
    })

    const data = await getData()

    sheet.getColumn('A').width = 20
    sheet.getColumn('B').width = 20
    sheet.getColumn('C').width = 20
    sheet.getColumn('D').width = 20
    sheet.getColumn('E').width = 20
    sheet.getColumn('F').width = 20

    sheet.getColumn('E').numFmt = "$ #,##0.00"
    sheet.getColumn('F').numFmt = "$ #,##0.00"

		const A1 = sheet.getCell('A1')
		console.log(A1)
		A1.value = `Inventario ${factoryName}`;
		A1.font = {
			bold: true,
			size: 24,
		}

    sheet.addTable({
      name: 'Inventario',
      ref: 'A3',
      headerRow: true,
      totalsRow: true,
      columns: COLUMNS,
      style: {
        theme: 'TableStyleLight1',
        showRowStripes: true,
      },
      rows: data.map((d) => [
				d.supplyName,
				Number(d.purchasedSupp),
				d.usedSupp,
				d.currentQuantity,
				d.price,
				d.totalPrice,
				d.unidad,
      ]),
    })

    return wb.xlsx.writeBuffer()
  }

  const download = async () => {
    try {
      const buffer = await createExel()
      const content = new Blob([buffer])

      const uri = window.URL.createObjectURL(content)
      const link = document.createElement('a')

      link.setAttribute('href', uri)
      link.setAttribute('download', 'Inventario.xlsx')

      link.click()
    } catch (error) {
      console.log(error)
    }
  }

  return download
}

export default useGenerateExel
