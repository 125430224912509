import React, {
	createContext,
	useContext,
	useState,
	useCallback,
	useEffect,
	FC,
	PropsWithChildren,
} from 'react'
import { isEqual, isFunction } from 'lodash'
import { initialFilter, initialValues } from './ReferencesUIHelpers'
import axios from 'axios'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../../setup/redux/RootReducer'
import { UserComplete } from '../../../../.././../../base_project_backend/src/api/interfaces/usuarios.interface'
import useIntlCurrencyFormat from '../../../../../utils/customHooks/useIntlCurrencyFormat'
import DeleteZerosHelper from '../../../../../utils/functions/DeleteZerosHelper'

//Modelos
import { ReferencesModel } from './models'
import { SevenKPlusSharp, SpeakerNotesRounded } from '@mui/icons-material'
import { FactoryModel } from '../../../admin/pages/factories/models'

interface dataDataResultTableOrders {
	entities: any[]
	totalCount: number
	errorMessage: string
}
export interface resultTableReferences {
	data: dataDataResultTableOrders
	totalCount: number
}

type SetState<T = any> = React.Dispatch<React.SetStateAction<T>>
export type name_factoryId = {name: string, factoryId: number };

export interface ReferencesUIContextModel {
	queryParams: typeof initialFilter
	setQueryParamsBase: SetState<typeof initialFilter>
	setQueryParams: SetState<typeof initialFilter>
	valuesTable: resultTableReferences
	setValuesTable: SetState<resultTableReferences>
	selected: ReferencesModel | null
	selectedName: string
	isLoadingSelected: boolean
	tableLoading: boolean
	setTableLoading: SetState<boolean>
	isLoading: boolean
	setIsLoading: SetState<boolean>
	factories: FactoryModel[]
	loadTable: () => Promise<void>
	select: (id: name_factoryId | null) => Promise<void>
	openEditDialog: (id: name_factoryId | null) => void
	openDeleteDialog: (id: name_factoryId | null) => void
}
const UIContext = createContext<ReferencesUIContextModel | null>(null)

export function useReferencesUIContext() {
	return useContext(UIContext)
}

export const UIConsumer = UIContext.Consumer

export type ReferencesUIProviderProps = {
	UIEvents: any
}

const ReferencesUIProvider: FC<PropsWithChildren<ReferencesUIProviderProps>> = ({
	children,
	UIEvents,
}) => {
	const [queryParams, setQueryParamsBase] = useState(initialFilter)
	const [valuesTable, setValuesTable] = useState<resultTableReferences>(initialValues)
	const [selected, setSelected] = useState<ReferencesModel | null>(null)
	const [selectedName, setSelectedName] = useState('')
	const [factories, setFactories] = useState<FactoryModel[]>([]);

	const [tableLoading, setTableLoading] = useState(true)
	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingSelected, setIsLoadingSelected] = useState(false)

	const setQueryParams: SetState<typeof initialFilter> = useCallback((next) => {
		setQueryParamsBase((prev) => {
			if (isFunction(next)) {
				next = next(prev)
			}

			if (isEqual(prev, next)) {
				return prev
			}

			return next
		})
	}, [])

	const loadTable = useCallback(async () => {
		try {
			setTableLoading(true)
			const result = await axios.get(`${process.env.REACT_APP_API_URL}/references/table`, {
				params: {
					pageNumber: queryParams.pageNumber,
					pageSize: queryParams.pageSize,
					factoryId: queryParams.filter.factoryId,
					name: queryParams.filter.name,
					start: queryParams.filter.start,
					end: queryParams.filter.end,
				},
			})

			setValuesTable(result.data)
		} catch (error) {
			console.log(error)
			setValuesTable(initialValues)
		} finally {
			setTableLoading(false)
		}
	}, [
		queryParams.pageSize,
		queryParams.pageNumber,
		queryParams.sortField,
		queryParams.sortOrder,
		queryParams.filter,
	])

	const select = useCallback(async (id: name_factoryId | null) => {
		try {
			setIsLoadingSelected(true)
			if (id === null) {
				setSelected(null)
				setSelectedName('')
				return
			}
			
			const result = await axios.get(`${process.env.REACT_APP_API_URL}/references/${id.name}/${id.factoryId}`);
			setSelected(result.data);
			setSelectedName(id.name);
		} catch (error) {
			console.log(error)
			setSelected(null)
			setSelectedName('')
		} finally {
			setIsLoadingSelected(false)
		}
	}, []);

	useEffect(() => {
		loadTable()
	}, [
		queryParams.pageSize,
		queryParams.pageNumber,
		queryParams.sortField,
		queryParams.sortOrder,
		queryParams.filter,
	]);

	useEffect(() => {
		(async() => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_API_URL}/factory`);
				setFactories(response.data);
			} catch (error) {
				setFactories([])
			}
		})()
	}, [])

	return (
		<UIContext.Provider
			value={{
				queryParams,
				setQueryParams,
				setQueryParamsBase,
				isLoading,
				setIsLoading,
				tableLoading,
				setTableLoading,
				valuesTable,
				setValuesTable,
				loadTable,
				selected,
				isLoadingSelected,
				select,
				selectedName,
				openEditDialog: UIEvents.openEditDialog,
				openDeleteDialog: UIEvents.openDeleteDialog,
				factories,
			}}
		>
			{children}
		</UIContext.Provider>
	)
}

export default ReferencesUIProvider
