import React, { FC, useEffect, useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import DialogHeader from './DialogHeader'
import { useSupplyProjectionUIContext } from '../SupplyProjectionUIContext'
import { SupplyProjectionModel } from '../models'
import EditForm from './EditForm'
import useModal from 'antd/lib/modal/useModal'
import Swal from 'sweetalert2'
import axios from 'axios'

interface EditDialogProps {
	id: number | null
	show: boolean
	onHide: () => void
}

const EditDialog: FC<EditDialogProps> = ({ id, show, onHide }) => {
	const UIContext = useSupplyProjectionUIContext()
	const UIProps = useMemo(
		() => ({
			select: UIContext?.select ?? (async (id: number | null) => { }),
			selected: UIContext?.selected ?? null,
			isLoadingSelected: UIContext?.isLoadingSelected ?? true,
			loadTable: UIContext?.loadTable,
		}),
		[UIContext]
	)

	const save = async (values: SupplyProjectionModel) => {
		try {
			if (id === null) {
				await axios.post(`${process.env.REACT_APP_API_URL}/supplyProjection`, values)
				Swal.fire({
					icon: 'success',
					title: 'Proyección de insumos creada correctamente',
				})
			} else {
				await axios.put(`${process.env.REACT_APP_API_URL}/supplyProjection/${id}`, values)
				Swal.fire({
					icon: 'success',
					title: 'Proyección de insumos actualizada correctamente',
				})
			}
			UIProps.loadTable?.()
		} catch (error) {
			Swal.fire({
				icon: 'error',
				title: 'Ha ocurrido un error',
			})
		} finally {
			onHide()
		}
	}

	useEffect(() => {
		UIProps.select(id)
	}, [id])

	return (
		<Modal
			size='lg'
			show={show}
			onHide={() => {
				UIProps.select(null)
				onHide()
			}}
			aria-labelledby='example-modal-sizes-title-lg'
			backdrop="static"
		>
			<DialogHeader id={id as number} />
			<EditForm
				save={save}
				loadingData={UIProps.isLoadingSelected}
				data={UIProps.selected}
				onHide={onHide}
			/>
		</Modal>
	)
}

export default EditDialog
